import "./modules/main-nav";
import "./modules/search-field";
import "./modules/mobile-nav";
import "./modules/introSectionSquares";
import "./modules/swiper";
import "./modules/accordeon";
import "./modules/template-common";
import "./modules/catalogTileCardSquares";
import "./modules/modal";

import "./modules/reviewCardLimitStr";
import "./modules/reviewModalFill";
